import React, { useRef } from 'react';
import Seo from '../components/seo';
import Layout from '../components/Layout';
import { Container, Row, Col, Button, CardDeck } from 'reactstrap';
import Waves from '../components/waves';
import { GatsbyImage } from 'gatsby-plugin-image';
import { scroller } from 'react-scroll';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import SaleProducts from '../components/SaleProduct/SaleProducts';
import SaleProductProvider from '../SaleProduct/SaleProductContext';

import arrow from '../images/homepage-new/arrow.svg';
import video from '../images/your-chance/gif.mp4';

const Page = ({ data }) => {
    const slider = useRef<Slider>();

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: false,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                },
            },
        ],
    };

    const handlePrev = () => {
        slider.current.slickPrev();
    };

    const handleNext = () => {
        slider.current.slickNext();
    };
    return (
        <Layout page="last-chance" showMenu={true}>
            <Seo
                title="Ostatnia szansa"
                description="Wymarzona sylwetka, dobre samopoczucie i energia. Dzięki dożywotniemu dostępowi do diety i treningów już zawsze będą one Twoje! Zagwarantuj sobie nieograniczony czasowo dostęp do wszystkich funkcji Diet & Training by Ann."
            />
            <section className="hero">
                <Waves />
                <div className="hero-img">
                    <GatsbyImage
                        image={data.heroImg.childImageSharp.gatsbyImageData}
                        alt=""
                    />
                </div>
                <Container>
                    <Row>
                        <Col xs="12" md="5">
                            <h1 className="mb-0">twoja szansa</h1>
                            <div className="discount-box">
                                <span className="precent">15</span>
                                <span> rabatu</span>
                            </div>
                            <Button
                                color="pink-font"
                                className="d-md-none w-75"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Odbierz rabat teraz
                            </Button>

                            <Button
                                color="pink-font"
                                className="d-none d-md-block mt-5 w-60"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Odbierz rabat teraz
                            </Button>
                        </Col>
                        <Col xs="10" className="mx-auto d-md-none pt-4">
                            <GatsbyImage
                                image={
                                    data.heroImg.childImageSharp.gatsbyImageData
                                }
                                style={{
                                    marginLeft: '-15px',
                                    marginRight: '-15px',
                                }}
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="promo border-bottom-0">
                <h2 className="text-center">
                    Pierwszy krok <br className="d-md-none" />{' '}
                    <span className="pink">należy do Ciebie</span>
                </h2>
                <Container className="mt-5">
                    <Row className="align-items-center">
                        <Col xs={{ size: '12', order: 2 }} md={{size: 7, order:1 }}>
                            <GatsbyImage
                                image={
                                    data.mobile.childImageSharp.gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                        <Col
                            xs={{ size: '12', order: 1 }}
                            md="5"
                            className="text-center text-md-left"
                        >
                            <h3>Dieta</h3>
                            <ul className="ullist">
                                <li>
                                    <p>
                                        4 typy diety do wyboru - opcje <br />{' '}
                                        klasyczne lub wege
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        Indywidualnie dopasowany jadłospis{' '}
                                        <br /> z listą zakupów
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Wymiana całych posiłków lub <br />{' '}
                                        pojedynczych produktów
                                    </p>
                                </li>
                                <li>
                                    <p>Opieka dietetyków online</p>
                                </li>
                                <li>
                                    <p>Szybkie przepisy krok po kroku!</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="align-items-center mt-5 pt-5">
                        <Col
                            xs="12"
                            md="5"
                            className="text-center text-md-left"
                        >
                            <h3>Treningi</h3>
                            <ul className="ullist">
                                <li>
                                    <p>Ponad 90 programów treningowych</p>
                                </li>

                                <li>
                                    <p>Różny poziom trudności treningów</p>
                                </li>
                                <li>
                                    <p>5 trenerów</p>
                                </li>
                                <li>
                                    <p>Ćwiczysz kiedy i jak długo chcesz</p>
                                </li>
                                <li>
                                    <p>
                                        Treningi dla kobiet i mężczyzn, kobiet w
                                        trakcie i po ciąży, par, osób dojrzałych
                                    </p>
                                </li>
                                <li>
                                    <p>Yoga dla każdego</p>
                                </li>
                            </ul>
                        </Col>
                        <Col xs="12" md="7">
                            <video
                                className="video img-fluid"
                                aria-hidden="true"
                                muted
                                autoPlay
                                playsInline
                                loop
                            >
                                <source src={video} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="slider">
                <Waves />
                <Container>
                    <h2 className="text-center">
                        Dodatkowe funkcje, które pokochasz!
                    </h2>

                    <div className="slider-container mt-5">
                        <Slider ref={slider} {...settings}>
                            <div>
                                <Row className="align-items-center">
                                    <Col xs="12" md="6">
                                        <h2 className="d-md-none text-center">
                                            Śledzenie postępów
                                        </h2>
                                        <GatsbyImage
                                            image={
                                                data.profile.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="6"
                                        className="text-center text-md-left"
                                    >
                                        <h2 className="d-none d-md-block">
                                            Śledzenie postępów
                                        </h2>
                                        <p className="mt-5 mt-md-0">
                                            Sprawdź ile drogi już za Tobą oraz{' '}
                                            <br />
                                            monitoruj masę ciała i pomiary -{' '}
                                            <br />
                                            zobaczysz, że CEL JEST JUŻ BLISKO!
                                        </p>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row className="align-items-center">
                                    <Col xs="12" md="6">
                                        <h2 className="d-md-none text-center">
                                            Nawodnienie
                                        </h2>
                                        <GatsbyImage
                                            image={
                                                data.hydration.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="6"
                                        className="text-center text-md-left"
                                    >
                                        <h2 className="d-none d-md-block">
                                            Nawodnienie
                                        </h2>
                                        <p className="mt-5 mt-md-0">
                                            Miej pewność, że dostarczasz swojemu
                                            organizmowi wystarczającą ilość
                                            wody.
                                        </p>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row className="align-items-center">
                                    <Col xs="12" md="6">
                                        <h2 className="d-md-none text-center">
                                            świadomy oddech
                                        </h2>
                                        <GatsbyImage
                                            image={
                                                data.breathing.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="6"
                                        className="text-center text-md-left"
                                    >
                                        <h2 className="d-none d-md-block">
                                            świadomy oddech
                                        </h2>
                                        <p className="mt-5 mt-md-0">
                                            Zestawy ćwiczeń, które pomogą się
                                            uspokoić, skupić na chwili bieżącej
                                            lub opanować emocje.
                                        </p>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row className="align-items-center">
                                    <Col xs="12" md="6">
                                        <h2 className="d-md-none text-center">
                                            balans
                                        </h2>
                                        <GatsbyImage
                                            image={
                                                data.balance.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="6"
                                        className="text-center text-md-left"
                                    >
                                        <h2 className="d-none d-md-block">
                                            balans
                                        </h2>
                                        <p className="mt-5 mt-md-0">
                                            Wyjątkowe ścieżki dźwiękowe i
                                            relaksująca muzyka - odzyskaj
                                            równowagę i oderwij się od
                                            codzienności.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Slider>
                        <div className="text-center text-md-left mt-5 mt-md-0">
                            <button
                                className="slider-btn prev"
                                onClick={handlePrev}
                            >
                                <img
                                    src={arrow}
                                    className="rotate180deg"
                                    alt="wstecz"
                                />
                            </button>
                            <button
                                className="slider-btn next"
                                onClick={handleNext}
                            >
                                <img src={arrow} alt="dalej" />
                            </button>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="start-today text-center border-bottom-0">
                <h2>
                    Zacznij <span className="pink">już dziś!</span>
                </h2>
                <div className="logo-15 mx-auto mt-5">
                    <GatsbyImage
                        image={data.logo15.childImageSharp.gatsbyImageData}
                        alt=""
                    />
                </div>
                <h3 className="mt-5">
                    Natychmiastowy dostęp <br className="d-md-none" /> do
                    aplikacji
                </h3>
                <p className="w-100 w-md-50 mx-auto mt-5 p-2 p-md-0">
                    Już kilka chwil po zakupie otrzymasz możliwość korzystania{' '}
                    <br className="d-none d-md-block" />
                    z Diet & Training by Ann gdziekolwiek i na dowolnym
                    urządzeniu - <br className="d-none d-md-block" /> na swoim
                    komputerze, telefonie i tablecie, a nawet Apple Watch!{' '}
                </p>
            </section>

            <section className="product-section">
                <Waves />
                <Container id="buy">
                    <Row>
                        <Col md="4">
                            <div className="d-flex flex-column justify-content-between h-100 align-items-center align-items-md-start">
                                <div className="discount">
                                    <span className="special-offer d-md-none">
                                        promocja limitowana
                                    </span>
                                    <div className="discount-box d-md-none mt-3">
                                        <span className="precent">15</span>
                                        <span> rabatu</span>
                                    </div>
                                    <span className="special-offer d-none d-md-block">
                                        promocja limitowana
                                    </span>
                                    <p className="d-none d-md-block">
                                        <span>15%</span> rabatu
                                    </p>
                                </div>

                                <p>
                                    Spróbuj wszystkich funkcji w <br /> cenach,
                                    które się nie powtórzą
                                </p>
                            </div>
                        </Col>

                        <Col md="8">
                            <CardDeck>
                                <SaleProductProvider
                                    tag="landing:treningi:dietAndWorkout"
                                    source="ostatnia-szansa"
                                    promoCode="ostatnia-szansa"
                                >
                                    <SaleProducts
                                        color="transparent"
                                        showThumbnail={false}
                                        showShortDescription={false}
                                        showBestseller={false}
                                        showOldPrice={false}
                                        buttonText="Kup w promocji"
                                    />
                                </SaleProductProvider>
                            </CardDeck>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query {
        heroImg: file(
            relativePath: { eq: "last-chance/main_img_lp_last_chance.png" }
        ) {
            ...fluidImage1920
        }
        mobile: file(relativePath: { eq: "your-chance/diet_screen.jpg" }) {
            ...fluidImage1920
        }
        exercise: file(relativePath: { eq: "trainings/exercise_screen.png" }) {
            ...fluidImage1920
        }

        profile: file(relativePath: { eq: "trainings/profile.png" }) {
            ...fluidImage1920
        }

        hydration: file(relativePath: { eq: "trainings/hydration.png" }) {
            ...fluidImage960
        }

        balance: file(relativePath: { eq: "trainings/balance.png" }) {
            ...fluidImage960
        }

        breathing: file(relativePath: { eq: "trainings/breathing.png" }) {
            ...fluidImage960
        }

        logo15: file(relativePath: { eq: "last-chance/logo_15.jpg" }) {
            ...fluidImage960
        }
    }
`;
